<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách hóa đơn đã xóa'">
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col md="2">
            <b-input
              placeholder="Nhập ID"
              v-model="searchId"
              class="btn-sm"
              size="sm"
              v-on:keyup.enter="onFilter"
            ></b-input>
          </b-col>
          <b-col md="3">
            <b-input
              placeholder="Nhập tên KH/Sdt"
              v-model="searchNameOrPhone"
              v-on:keyup.enter="onFilter"
              class="btn-sm"
              size="sm"
            ></b-input>
          </b-col>
          <b-col md="3">
            <b-input
              placeholder="Nhập tên sản phẩm"
              v-model="searchProductName"
              class="btn-sm"
              size="sm"
              v-on:keyup.enter="onFilter"
            ></b-input>
          </b-col>
          <b-col md="2">
            <b-input-group>
              <date-picker
                placeholder="Từ ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="dateFrom"
              ></date-picker>
            </b-input-group>
          </b-col>
          <b-col md="2">
            <b-input-group>
              <date-picker
                placeholder="Tới ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="dateTo"
              ></date-picker>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="2">
            <vue-autosuggest
              v-model="searchEmployee"
              :suggestions="filteredOptionsEmployee"
              @selected="onSelectedEmployee"
              :limit="10"
              @input="onInputChangeEmployee"
              :input-props="{
                id: 'autosuggest__input',
                placeholder: 'Nhân viên bán hàng',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.fullName }}
              </div></vue-autosuggest
            >
          </b-col>
          <b-col md="3">
            <BDropdownCustom
              v-model="valueStore"
              :options="filteredStore"
              :placeholder="'cửa hàng'"
              :matchKey="['name']"
              :searchable="true"
              :limit="5"
            />
          </b-col>
          <b-col md="3">
            <BDropdownCustom
              v-model="value"
              :options="filterdOptions"
              :placeholder="'đặc điểm'"
              :matchKey="['name']"
              :searchable="true"
              :limit="5"
            />
          </b-col>
          <b-col> </b-col>
          <b-col> </b-col>
          <b-col md="2"> </b-col>
          <b-col md="2"> </b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>

        <table class="table b-table table-bordered-list table-hover col-md-12">
          <thead>
            <tr>
              <th class="text-center tdDate">Ngày</th>
              <th class="text-center tdCode">ID</th>
              <th class="text-center tdStore">Cửa hàng</th>
              <th class="text-center tdCustomer">Khách hàng</th>
              <th class="text-center tdProductName">Sản phẩm</th>
              <th class="text-center tdProductPrice">Giá</th>
              <th class="text-center tdQuantity">SL</th>
              <th class="text-center tdDiscountItem">C.Khấu</th>
              <th class="text-center tdTotalAmount">Tổng tiền</th>
              <th class="text-center tdTotalPayment">Thanh toán</th>
              <th class="text-center tdNote">Ghi chú</th>
              <th class="text-center tdAction">Người xóa</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="entry in bills"
              :key="entry.id"
              @mouseover="hoverBill"
              @mouseleave="outBill"
              :data="`row_${entry.billNumber}`"
            >
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign"
              >
                {{ entry.date }}
                <br />
                {{ entry.saleName }}
                <br />
                <span style="font-weight: 500"> Xóa lúc:</span>
                {{ entry.deletedAt }}
              </td>
              <td class="tdCenter" v-if="entry.show" :rowspan="entry.groupSize">
                <div
                  class="orderCode"
                  @click="editItem(entry)"
                  style="cursor: pointer; color: #3699ff"
                >
                  <span v-text="entry.billNumber"></span>
                </div>
                <p>[{{ entry.billTypeName }}]</p>
                <div v-if="entry.type === 4">
                  <p style="color: blue">HĐGH từ đơn hàng</p>
                  <div
                    class="orderCode"
                    @click="linkToOrder(entry.orderId)"
                    style="cursor: pointer"
                  >
                    <span
                      style="color: darkgoldenrod"
                      v-text="entry.orderId"
                    ></span>
                  </div>
                </div>
                <div v-else-if="entry.type === 1 && entry.orderId">
                  <p style="color: blue">Từ đơn hàng</p>
                  <div
                    class="orderCode"
                    @click="linkToOrder(entry.orderId)"
                    style="cursor: pointer"
                  >
                    <span
                      style="color: darkgoldenrod"
                      v-text="entry.orderId"
                    ></span>
                  </div>
                </div>
                <div v-else-if="entry.type === 3">
                  <p style="color: blue">Từ hóa đơn</p>
                  <div class="orderCode" style="cursor: pointer">
                    <span v-text="entry.returnFromBillNumber"></span>
                  </div>
                </div>
              </td>
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign"
              >
                {{ entry.storeName }}
              </td>
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign"
              >
                <p
                  class="orderCode"
                  @click="editUser(entry)"
                  style="margin-bottom: 0; cursor: pointer; font-weight: 550"
                >
                  {{ entry.customerName }}
                </p>
                <p
                  class="orderCode"
                  @click="editUser(entry)"
                  style="margin-bottom: 0; cursor: pointer"
                >
                  {{ entry.customerPhone }}
                </p>
              </td>
              <td class="tdTextAlign">
                <div>
                  <div
                    v-if="entry.productItem.productType === 2"
                    class="font-size-gift"
                  >
                    <i
                      style="color: #2e7d32"
                      class="fas fa-gift ml-4 mr-1"
                      title="Quà tặng"
                    ></i>
                    {{ entry.productItem.productName }}
                  </div>
                  <div
                    v-else-if="entry.productItem.productType === 3"
                    class="font-size-attach"
                  >
                    <i
                      style="color: #37474f"
                      class="fas fa-tags ml-4 mr-1"
                      title="Bán kèm"
                    ></i>
                    {{ entry.productItem.productName }}
                  </div>
                  <div v-else>
                    {{ entry.productItem.productName }}
                  </div>
                  <p
                    class="text-imei-no"
                    v-if="entry.productItem.imeiCode.trim() !== ''"
                  >
                    IMEI: {{ entry.productItem.imeiCode }}
                  </p>
                </div>
              </td>
              <td class="tdTextAlignPrice">
                {{ convertPrice(entry.productItem.productPrice) }}
              </td>
              <td class="tdTextAlignPrice">
                {{ entry.productItem.quantity }}
              </td>
              <td class="tdTextAlignPrice">
                {{ convertPrice(entry.productItem.discount) }}
              </td>
              <td
                class="tdTextAlignPrice"
                v-if="entry.show"
                :rowspan="entry.groupSize"
                style="color: #ffa800"
              >
                <p
                  v-show="
                    entry.discountAmount > 0 ||
                    entry.discountAmount.includes('%')
                  "
                  class="border-0"
                >
                  <i
                    v-b-tooltip.hover
                    title="Chiết khấu"
                    class="fa fa-arrow-down icon-color"
                  ></i>
                  : {{ entry.discountAmount }}
                </p>
                {{ convertPrice(entry.totalAmount) }}
              </td>
              <td
                class="tdTextAlignPrice"
                v-if="entry.show"
                :rowspan="entry.groupSize"
              >
                <p v-show="entry.pointToMoney > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Tiêu điểm"
                    class="fas fa-adjust icon-color"
                  ></i>
                  : {{ convertPrice(entry.pointToMoney) }}
                </p>
                <p v-show="entry.transferAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Đã chuyển khoản"
                    class="fa fa-credit-card icon-color"
                  ></i>
                  : {{ convertPrice(entry.transferAmount) }}
                </p>
                <p v-show="entry.creditAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Tiền quẹt thẻ"
                    class="fa fa-credit-card icon-color"
                  ></i>
                  : {{ convertPrice(entry.creditAmount) }}
                </p>
                <p v-show="entry.cashAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Tiền mặt"
                    class="fa fa-money-check-alt icon-color"
                  ></i>
                  : {{ convertPrice(entry.cashAmount) }}
                </p>
                <p v-show="entry.installlAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Trả góp"
                    class="fa fa-money-check-alt icon-color"
                  ></i>
                  : {{ convertPrice(entry.installlAmount) }}
                </p>
                <span style="font-weight: 500" class="border-0 text-success">{{
                  convertPrice(entry.paymentAmount)
                }}</span>
                <p v-show="entry.leftAmount > 0" class="border-0 text-warning">
                  Còn thiếu: {{ convertPrice(entry.leftAmount) }}
                </p>
              </td>
              <td
                class="tdTextAlign"
                v-if="entry.show"
                :rowspan="entry.groupSize"
                @mouseover="hoverPrice = true"
                @mouseleave="hoverPrice = false"
              >
                {{ entry.note }}
                <span @click="handleShowNoteModel(entry)">
                  <i v-if="hoverPrice" class="fas fa-edit"></i>
                </span>
              </td>
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign tdCenter"
              >
                <div class="d-flex justify-content-center">
                  {{ entry.deletedBy }}
                </div>
              </td>
            </tr>
            <tr>
              <td :colspan="4" class="tdTextAlignPrice text-weight">Tổng</td>
              <td></td>
              <td></td>
              <td class="tdTextAlignPrice text-weight">
                {{ totalQuantity }}
              </td>
              <td></td>
              <td class="tdTextAlignPrice text-weight">
                {{ convertPrice(totalAmount) }}
              </td>
              <td class="tdTextAlignPrice text-weight">
                {{ convertPrice(totalPayment) }}
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <vcl-table
          v-if="onLoading"
          :speed="5"
          :animate="true"
          :columns="11"
        ></vcl-table>
        <!-- End of Table session -->

        <template>
          <b-modal
            v-model="showEditPriceModal"
            hide-footer
            :title="'Sửa ghi chú cho hóa đơn ' + idBill"
          >
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice"
                  >Ghi chú</label
                >
                <b-form-textarea
                  id="textarea"
                  v-model="note"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateNote"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowNoteModel"
                  >Hủy</b-button
                >
              </b-col>
            </b-row>
          </b-modal>
        </template>

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ convertPrice(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-model="page"
              v-show="totalPages >= 2"
              class="custom-pagination"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchListBill"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import decounce from 'debounce';
import { VclTable } from 'vue-content-loading';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { cloneDeep, map, assign } from 'lodash';
import { removeAccents } from '@/utils/common';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

import moment from 'moment';
import { TIME_TRIGGER } from '@/utils/constants';

export default {
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      hoverPrice: false,
      dateFrom: moment().subtract(15, 'days').format('DD/MM/YYYY'),
      dateTo: moment().format('DD/MM/YYYY'),
      bills: [],
      onLoading: false,
      isSearching: false,
      currentPage: 0,
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      searchProductName: '',
      searchId: '',
      searchNameOrPhone: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      idBill: '',
      showEditPriceModal: false,
      note: '',
      totalAmount: 0,
      totalPayment: 0,
      totalQuantity: 0,
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      selectedStore: null,
      searchStock: '',
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      selectedEmployee: null,
      searchEmployee: '',
      listStatus: [
        {
          id: 1,
          name: 'Hoạt động',
        },
        {
          id: 2,
          name: 'Đã khóa',
        },
      ],
      selectedStatus: 1,
      value: [],
      options: [
        {
          code: 1,
          name: 'Có tiền chiết khấu',
          shortName: 'Có tiền chiết khấu',
          checked: false,
        },
        {
          code: 2,
          name: 'Không có chiết khấu',
          shortName: 'Không có chiết khấu',
          checked: false,
        },
        {
          code: 3,
          name: 'Có tiền chuyển khoản',
          shortName: 'Có tiền chuyển khoản',
          checked: false,
        },
        {
          code: 4,
          name: 'Có thanh toán quẹt thẻ',
          shortName: 'Có thanh toán quẹt thẻ',
          checked: false,
        },
        {
          code: 5,
          name: 'Có thanh toán trả góp',
          shortName: 'Có thanh toán trả góp',
          checked: false,
        },
        {
          code: 6,
          name: 'Có dùng tiền tích điểm',
          shortName: 'Có dùng tiền tích điểm',
          checked: false,
        },
        {
          code: 7,
          name: 'Có công nợ',
          shortName: 'Có công nợ',
          checked: false,
        },
        {
          code: 8,
          name: 'Còn công nợ',
          shortName: 'Còn công nợ',
          checked: false,
        },
        {
          code: 9,
          name: 'Hết công nợ',
          shortName: 'Hết công nợ',
          checked: false,
        },
        {
          code: 10,
          name: 'Có tiền mặt',
          shortName: 'Có tiền mặt',
          checked: false,
        },
        {
          code: 11,
          name: 'Hóa đơn từ đơn hàng',
          shortName: 'Hóa đơn từ đơn hàng',
          checked: false,
        },
        {
          code: 12,
          name: 'Có quà tặng',
          shortName: 'Có quà tặng',
          checked: false,
        },
      ],
      valueStore: [],
      filteredStore: [],
      filterdOptions: [],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    datePicker,
    BDropdownCustom
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn', route: 'bills' },
      { title: 'Danh sách hóa đơn đã xóa' },
    ]);
    this.page = this.$route.query.page;
    if (!this.page) {
      this.page = 1;
    }
  },
  created() {
    this.fetchStore();
    this.fetchStoreByUser();
    this.getListEmployee();
  },
  methods: {
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.optionsStore = stores.map((element) => {
          return {
            code: element.id,
            name: element.name,
            shortName: element.shortName,
            checked: false,
          };
        });
      });
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    editItem: function (item) {
      let nameRouter = '';
      if (item.type === 1 || item.type === 4) {
        nameRouter = 'retail-bill-deleted';
      } else if (item.type === 2) {
        nameRouter = 'wholesale-bill-deleted';
      } else if (item.type === 3) {
        nameRouter = 'refund-bill-deleted';
      }
      this.$router.push({
        name: nameRouter,
        query: { id: item.billNumber },
      });
    },
    returnItem: function (item) {
      this.$router.push({
        name: 'add-return-bill',
        query: { id: item.billNumber },
      });
    },
    handleShowNoteModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.idBill = item.billNumber;
      this.note = item.note;
    },
    updateNote() {
      const data = {
        id: this.idBill,
        customerNote: this.note,
      };
      ApiService.put('bills/update-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.showEditPriceModal = !this.showEditPriceModal;
            this.fetchListBill();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    searchBill() {
      this.isSearching = true;
      this.fetchListBill();
    },
    fetchListBill: async function () {
      let listStoreId = [];
      if (this.valueStore.length) {
        listStoreId = this.valueStore.map(({ code}) => code);
      };
      this.filteredStore = cloneDeep(this.optionsStore);
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      this.onLoading = true;
      if (this.searchStock.trim() === '') {
        this.selectedStore = null;
      }
      let listCriteria = [];
      if (this.value.length) {
        listCriteria = this.value.map(({ code}) => code);
      };
      this.filterdOptions = cloneDeep(this.options);
      const param = {
        page: this.page,
        limit: 10,
        id: this.searchId,
        searchCustomer: this.searchNameOrPhone,
        store: listStoreId,
        searchProduct: this.searchProductName,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
          : null,
        saleId: this.selectedEmployee,
        listCriteria: listCriteria,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('bills/bill-deleted', paramQuery)
        .then(({ data }) => {
          this.bills = [];
          this.totalPages = data.data.totalPage;
          this.totalItems = data.data.totalRow;
          this.totalQuantity = 0;
          this.totalAmount = 0;
          this.totalPayment = 0;
          data.data.data.forEach((data) => {
            for (let index = 0; index < data.listProduct.length; index++) {
              const element = data.listProduct[index];
              let show = false;
              if (index === 0) {
                show = true;
              }
              let discountAmount = '';
              if (data.discountType === 2) {
                discountAmount = data.discountAmount + '%';
              } else if (data.discountType === 1) {
                discountAmount = this.convertPrice(data.discountAmount);
              }
              const item = {
                id: element.id,
                billNumber: data.billNumber,
                customerName: data.customerName,
                customerPhone: data.customerMobile,
                saleName: data.saleName,
                storeName: data.storeName,
                totalAmount: data.totalAmount,
                paymentAmount: data.paymentAmount,
                creditAmount: data.creditAmount,
                cashAmount: data.cashAmount,
                transferAmount: data.transferAmount,
                leftAmount: data.leftAmount,
                discountAmount: discountAmount,
                note: data.customerNote,
                productItem: element,
                date: data.createdAt,
                show: show,
                groupSize: data.listProduct.length,
                productType: element.productType,
                type: data.type,
                billTypeName: data.billTypeName,
                orderId: data.orderId,
                installlAmount: data.installlAmount,
                customerId: data.customerId,
                pointToMoney: data.pointToMoney,
                returnFromBillNumber: data.returnFromBillNumber,
                deletedBy: data.deletedBy,
                deletedAt: data.updatedAt,
              };
              this.bills.push(item);
              this.totalQuantity += element.quantity;
              if (show) {
                this.totalAmount += data.totalAmount;
                this.totalPayment += data.paymentAmount;
              }
            }
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
          this.makeToastFaile('Bạn không có quyền thực hiện thao tác này');
        });
    },
    deleteItem: async function (item) {
      ApiService.delete('/bills' + '/' + item.billNumber).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchListBill();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa hóa đơn!',
        text: 'Bạn có chắc muốn xóa hóa đơn này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('BILL_VIEW')) {
        count++;
      }
      if (localData.checkPermission('BILL_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    linkToOrder: function (orderId) {
      const { href } = this.$router.resolve({
        name: 'update-order',
        query: { id: orderId },
      });
      window.open(href, '_blank');
    },
    debounceInput: decounce(() => {
      this.searchBill();
    }, TIME_TRIGGER),
    editUser: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-customer',
        query: { id: item.customerId, phoneNo: item.customerPhone },
      });
      window.open(href, '_blank');
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      if (this.searchStock.trim() === '') {
        this.selectedStore = null;
      }
    },
    hoverBill: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.addClass('tr-rowspan-hover');
    },
    outBill: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            const option = {
              code: element.id,
              name: element.name,
              shortName: element.shortName,
              checked: false,
            };
            this.optionsStore.push(option);
            if (!data.data.viewAll) {
              let index = this.optionsStore.findIndex(
                (item) => item.code == element.id
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
            }
          });
          this.filteredStore = cloneDeep(this.optionsStore);
        }
        this.fetchListBill();
        this.getListEmployee();
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.changeURL();
      this.fetchListBill();
    },
    changeURL() {
      this.$router.push({
        name: 'list-bill-deleted',
      });
    },
    onSelectedEmployee(option) {
      this.selectedEmployee = option.item.id;
      this.searchEmployee = option.item.fullName;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      this.debounceInputEmployee();
    },
    debounceInputEmployee: decounce(function () {
      this.getListEmployee();
    }, TIME_TRIGGER),
    getListEmployee() {
      this.optionsEmployee[0].data = [];
      this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];
      if (this.searchStock.trim() === '') {
        this.selectedStore = null;
      }
      ApiService.get(
        `employees/get-by-store?employeeName=${this.searchEmployee}&storeId=${this.selectedStore}`
      ).then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          this.optionsEmployee[0].data.push(element);
        });
        this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];
      });
    },
    onSelect(option) {
      let index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = true;
      this.filterdOptions = cloneDeep(this.options);
    },
    onRemove(option) {
      let index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = false;
      this.filterdOptions = cloneDeep(this.options);
    },
    customLabel(option) {
      return `${option.name}`;
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = true;
    },

    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = false;
    },
    customLabelStore(option) {
      return `${option.name}`;
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Store':
          this.searchStore(textInput);
          break;
        case 'Characteristic':
          this.searchCharacteristic(textInput);
          break;
        default:
          break;
      }
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionsStore);
      if (!textInput || !textInput.trim().length) {
        this.filteredStore = map(options, (obj) => {
          return assign(obj, find(this.filteredStore, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredStore, { id: obj.id }));
      });

      this.filteredStore = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    searchCharacteristic(textInput) {
      let options = cloneDeep(this.options);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptions = map(options, (obj) => {
          return assign(obj, find(this.filterdOptions, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filterdOptions, { id: obj.id }));
      });

      this.filterdOptions = this.fitlerOptionsBy(
        options,
        textInput,
        'name',
        10
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style>
.orderCode:hover {
  text-decoration: underline;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.tdCenter {
  vertical-align: left !important;
}
.tdTextAlign {
  vertical-align: middle;
}
.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
}
.text-imei-no {
  font-size: 12px;
  font-style: italic;
  color: dodgerblue;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.table-bordered-list th {
  border: 0.5px solid #d2d4d6 !important;
  background-color: #e0e0e0;
}
.table-bordered-list td {
  border: 0.5px solid #d2d4d6;
}
.text-weight {
  font-weight: 550;
}
.font-size-gift {
  font-size: 11px;
}
.font-size-attach {
  font-size: 11px;
}
.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}

.tdDate {
  width: 5%;
}
.tdCode {
  width: 5%;
}
.tdStore {
  width: 5%;
}
.tdCustomer {
  width: 7%;
}
.tdProductName {
  width: 15%;
}
.tdProductPrice {
  width: 10%;
}
.tdQuantity {
  width: 5%;
}
.tdDiscountItem {
  width: 8%;
}
.tdTotalAmount {
  width: 10%;
}
.tdTotalPayment {
  width: 10%;
}
.tdNote {
  width: 10%;
}
.tdAction {
  width: 5%;
}
</style>
